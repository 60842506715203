import axios from 'axios';

const API_BASE_URL = '/api/compositions/';

// export const fetchProducts = async (pageNum, categorySlug, selectedCategorySlug) => {
//   const urlBase = `${API_BASE_URL}?page=${pageNum}`;
//   const url = selectedCategorySlug ? `${urlBase}&subcategory=${selectedCategorySlug}` : `${urlBase}&category=${categorySlug}`;

//   try {
//     const response = await axios.get(url, {
//       withCredentials: true // Включает отправку cookies для этого запроса
//     });
//     return response.data; // Убедитесь, что возвращаете данные, а не всю ответ axios.
//   } catch (error) {
//     console.error('Ошибка при загрузке товаров:', error);
//     throw error;
//   }
// };

export const addToCart = async (compositionId, quantity = 1) => {
  const data = {
    composition_id: compositionId.toString(), // Убедитесь, что id товара - строка
    quantity: quantity // Устанавливаем количество товара
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Если используется токен, добавьте его здесь, например:
      // 'Authorization': `Bearer ${yourAuthToken}`
    },
    withCredentials: true // Это позволит передать куки с запросом, если они используются
  };

  try {
    const response = await axios.post('/api/cart/add_to_cart/', data, config);
    return response.data.count; // Возвращаем количество товаров в корзине
  } catch (error) {
    console.error('Ошибка при добавлении в корзину:', error);
    throw error;
  }
};

export const fetchCartCount = async () => {
  try {
    const response = await axios.get('/api/cart/count/');
    return response.data.count;
  } catch (error) {
    console.error('Ошибка при получении количества товаров в корзине:', error);
    throw error;
  }
};

export default {
  //fetchProducts,
  addToCart,
  fetchCartCount,
};
