import { useEffect, useRef } from 'react';

const useInfiniteScroll = (scrollRef, loadFunction, hasMore, loading) => {
  const observer = useRef();



  useEffect(() => {
    if (loading || !hasMore) return;
    if (observer.current) observer.current.disconnect();

    const callback = function(entries) {
      if (entries[0].isIntersecting) {
        loadFunction();
      }
    };

    observer.current = new IntersectionObserver(callback);
    observer.current.observe(scrollRef.current);

    return () => observer.current && observer.current.disconnect();
  }, [scrollRef, loadFunction, hasMore, loading]);
};

export default useInfiniteScroll;
