import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import thankYouImage from './heart_thank.png'; // Импортируйте картинку
import { useCart } from './CartContext';

const ThankYouPage = () => {
  const { setCartCount } = useCart();

  useEffect(() => {
    setCartCount(0); // Обнуляем счетчик корзины при загрузке страницы
  }, [setCartCount]);


  return (
    <Box sx={{ textAlign: 'center', padding: '50px' }}>
      <h1>Спасибо за ваш заказ!</h1>
      <p>Менеджер свяжется с вами в ближайшее время.</p>
      <Box
        component="img"
        src={thankYouImage} // Используйте импортированную картинку
        alt="Thank you"
        sx={{
          maxWidth: '100%',
          height: 'auto',
          mt: 4,
          maxHeight: '200px', // Максимальная высота
          width: 'auto' // Адаптивная ширина
        }}
      />
    </Box>
  );
};

export default ThankYouPage;
