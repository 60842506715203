import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Spectral", cursive', // Основной шрифт для текста
    h1: {
      fontFamily: '"Pacifico", cursive', // Шрифт для заголовков
      fontSize: '3.0rem', // Значение по умолчанию для мобильных устройств
      '@media (min-width:600px)': {
        // Для планшетов и выше
        fontSize: '4.0rem',
      },
      '@media (min-width:900px)': {
        // Для десктопов и выше
        fontSize: '5.0rem',
      },
    },
    h2: {
      fontFamily: '"Pacifico", cursive',
      fontSize: '2.0rem', // Значение по умолчанию для мобильных устройств
      '@media (min-width:600px)': {
        // Для планшетов и выше
        fontSize: '2.6rem',
      },
      '@media (min-width:900px)': {
        // Для десктопов и выше
        fontSize: '3.2rem',
      },
      color: '#f2003c',
    },
    h3: {
      fontFamily: '"Rhythmic", normal',
      fontSize: '2.0rem', // Значение по умолчанию для мобильных устройств
      '@media (min-width:600px)': {
        // Для планшетов и выше
        fontSize: '2.6rem',
      },
      '@media (min-width:900px)': {
        // Для десктопов и выше
        fontSize: '3.2rem',
      },
      color: '#f2003c',
    },
  },
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          m: 0, // Убрать внешние отступы
          p: 0, // Убрать внутренние отступы
          border: 'none', // Убрать рамку
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#f2003c', // Основной цвет кнопки
          '&:hover': {
            backgroundColor: 'lightpink', // Цвет кнопки при наведении
          },
        },
      },
    },
  },
});

export default theme;
