import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';
import { textFieldStyles, typographyStyles } from './formStyles';
import { useFormContext } from './FormContext';

const PickupForm = ({ setReceivingInfo }) => {
  const { control, setError, clearErrors, trigger, getValues, formState } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      date: '',
      time: '',
    }
  });

  const requiredMessage = "Это поле обязательно";

  const validateTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours >= 9 && hours < 21 && minutes >= 0 && minutes < 60;
  };

  const handleTimeChange = (field) => (event) => {
    const time = event.target.value;
    field.onChange(event);
    setReceivingInfo((prev) => ({ ...prev, time }));
    if (time.length === 5) {
      if (!validateTime(time)) {
        setError('time', {
          type: 'manual',
          message: "Пожалуйста, выберите рабочее время между 09:00 и 21:00."
        });
      } else {
        clearErrors('time');
      }
    }
  };

  const validateDate = (date) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(date)) {
      return false;
    }
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return selectedDate >= today;
  };
  
  const handleDateChange = (field) => (event) => {
    const date = event.target.value;
    field.onChange(event);
    setReceivingInfo((prev) => ({ ...prev, date }));
    if (date.length === 10) {
      if (!validateDate(date)) {
        setError('date', {
          type: 'manual',
          message: "Нельзя забрать в прошлом."
        });
      } else {
        clearErrors('date');
      }
    }
  };
  

  const { registerValidator } = useFormContext();

  useEffect(() => {
    registerValidator(async () => {
      const isValid = await trigger();
      const data = getValues();
      setReceivingInfo(data);
      return { isValid, data, errors: formState.errors };
    });
  }, [registerValidator, trigger, getValues, formState.errors, setReceivingInfo]);

  return (
    <form style={typographyStyles}>
      <Typography sx={typographyStyles}>
        Адрес магазина для самовывоза:
        <br /> г.Тольятти, ул. Фрунзе 14в,
        <br /> вход со стороны улицы Фрунзе. Вывеска большое розовое сердце "Хочу цветочки"
      </Typography>

      <Controller
        name="date"
        control={control}
        rules={{ required: requiredMessage }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Дата"
            type="date"
            fullWidth
            size='small'
            onChange={handleDateChange(field)}
            InputLabelProps={{ shrink: true }}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            sx={textFieldStyles}
          />
        )}
      />

      <Controller
        name="time"
        control={control}
        rules={{ required: requiredMessage }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Время"
            type="time"
            fullWidth
            size='small'
            onChange={handleTimeChange(field)}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            InputLabelProps={{ shrink: true }}
            sx={textFieldStyles}
          />
        )}
      />
    </form>
  );
};

export default PickupForm;
