import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const ReturnPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          Возврат и правовое положение
        </Typography>


        <Typography variant="h5" gutterBottom>
          Свежие цветы
        </Typography>
        <Typography variant="body1" paragraph>
          Мы в Хочу Цветочки, тщательно собираем каждый букет и гарантируем его свежесть и соответствие заказу, оформленному на сайте hochycvetochki.ru. Однако, в зависимости от сезонности и наличия цветов, мы оставляем за собой право заменять их в букете. Наши флористы делают всё возможное, чтобы цвет и форма букета максимально соответствовали изображениям на сайте.
        </Typography>
        <Typography variant="body1" paragraph>
          Обратите внимание, что согласно Закону Российской Федерации «О защите прав потребителей» от 07.02.1992 № 2300–1 и Постановлению Правительства Российской Федерации от 19.01.1998 № 55, срезанные цветы и горшечные растения обмену и возврату не подлежат.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Воздушные шары
        </Typography>
        <Typography variant="body1" paragraph>
          Гарантия на полет воздушных шаров компании Хочу Цветочки составляет 30 часов с момента получения заказа. Это распространяется на латексные и фольгированные шары, надутые гелием, за исключением латексных сердец размером 25 см и шаров с индивидуальной надписью или наклейкой. Гарантия действует при условии, что шарики находились в кондиционируемом помещении при температуре не выше 23 градусов и влажности не более 60%.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Гарантийные случаи:</strong>
          <ul>
            <li>Более 10% шариков упали до завершения гарантийного периода.</li>
            <li>Шарики потеряли более 50% объема до завершения гарантийного периода.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          В таких случаях сделайте фотографию дефектных шаров и отправьте нам её. Если случай будет признан гарантийным, мы оперативно заменим товар в течение 24 часов с момента обращения.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Негарантийные случаи:</strong>
          <ul>
            <li>Гарантия аннулируется, если шары сдулись по вине клиента. Пожалуйста, обращайтесь с шариками бережно, избегайте их контакта с острыми предметами, шероховатыми поверхностями и домашних животных, сильный перепад температур тоже вредит шарикам.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Рекомендации:</strong>
          <ul>
            <li>Избегайте перепадов температур, попадания прямых солнечных лучей и осадков на поверхность шаров.</li>
            <li>Не оставляйте надутые шары в машине.</li>
            <li>Дайте шарам отстояться 2-3 часа в комнате перед выпуском их под потолок, чтобы избежать воздействия статического электричества.</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Отказ от заказа
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Цветы:</strong>
          <ul>
            <li>Если вы решили отказаться от заказа до момента его получения, удерживается 20% от стоимости за срез цветка и использование упаковки.</li>
            <li>Если цветок ненадлежащего качества, и вы отказались от него при доставке или самовывозе, возвращается 100% оплаченной суммы таким же способом, как и было оплачено.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Воздушные шары:</strong>
          <ul>
            <li>Вы можете отказаться от заказанных шаров не позднее чем за 12 часов до назначенного времени получения.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          Если у вас есть какие-либо пожелания или вы остались недовольны нашими услугами, пожалуйста, позвоните нам или напишите в WhatsApp. Мы с радостью поможем вам.
        </Typography>
      </Box>
    </Container>
  );
};

export default ReturnPolicyPage;
