import React, { useState, useEffect } from 'react';
import { Box, TextField, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { useSession } from '../context/SessionContext';
import CartItems from './CartItems';
import { fetchCartItems, updateCartItemQuantity, removeCartItem, submitOrder } from './api';
import DeliveryForm from './DeliveryForm';
import PickupForm from './PickupForm';
import CustomerInfo from './CustomerInfo';
import Buttons from './Buttons';
import { textFieldStyles } from './formStyles';
import { FormProvider, useFormContext } from './FormContext';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { useCart } from '../Сart/CartContext';
import ModalContent from '../static/ModalContent';
import PrivacyPolicyPage from '../static/PrivacyPolicyPage';

const CartPage = () => {
  const { state } = useSession();
  const [cartItems, setCartItems] = useState([]);
  const [receivingType, setReceivingType] = useState('');
  const [cardText, setCardText] = useState('');
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    surname: '',
    phone: '+7'
  });
  const [receivingInfo, setReceivingInfo] = useState({});
  const [activeButton, setActiveButton] = useState('');
  const navigate = useNavigate(); // Создаем переменную для навигации
  const { fetchCartCount } = useCart();
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false); // состояние для отслеживания состояния чекбокса
const [privacyPolicyError, setPrivacyPolicyError] = useState(false); // состояние для отображения ошибки


  useEffect(() => {
    fetchCartItems(setCartItems);
  }, [state.sessionid]);

  const handleReceivingTypeChange = (type) => {
    setReceivingType(type);
    setActiveButton(type);
  };


  const handleQuantityChange = async (itemId, newQuantity) => {
    if (newQuantity <= 0) {
      await removeCartItem(itemId, async () => {
        await fetchCartItems(setCartItems);
      });
      fetchCartCount(); // Обновляем количество товаров в корзине
    } else {
      await updateCartItemQuantity(itemId, newQuantity, async () => {
        await fetchCartItems(setCartItems);
      });
      fetchCartCount(); // Обновляем количество товаров в корзине
    }
  };

  const { validateForms } = useFormContext();

  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    const submitOrderData = async () => {
      if (orderData) {

        const response = await submitOrder(orderData);
        setOrderData(null); // Сбрасываем данные заказа после отправки
        console.log(' после создания заказа response:', response);
        if (response && response.data && response.status === 201) {
          navigate('/thank-you'); // Перенаправляем на страницу благодарности
        } else {
          console.error('Произошла ошибка при отправке заказа:', response);
        }
      }
    };
    submitOrderData();
  }, [orderData, navigate]);
  

  // очищаем пустые строки
  const filterEmptyFields = (data) => {
    if (Array.isArray(data)) {
      return data.map(filterEmptyFields);
    } else if (data !== null && typeof data === 'object') {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, value]) => value !== '')
          .map(([key, value]) => [key, filterEmptyFields(value)])
      );
    } else {
      return data;
    }
  };

  useEffect(() => {
    console.log('Receiving Info updated:', receivingInfo);
  }, [receivingInfo]);
  
    const handleSubmitOrder = async () => {
      console.log("handleSubmitOrder called");
      console.log("Cart Before validation receivingInfo:", receivingInfo);
    
      const { isValid } = await validateForms(); // Убедитесь, что данные формы обновлены и валидированы
    
      console.log("Cart After validation receivingInfo:", receivingInfo); // Проверим состояние после валидации

      if (!isPrivacyPolicyAccepted) {
        setPrivacyPolicyError(true);
        console.log('Privacy policy not accepted');
        return;
      }
    
      if (isValid) {
        const orderData = {
          customer: {
            name: customerInfo.name,
            surname: customerInfo.surname,
            phone: customerInfo.phone
          },
          receiving_type: receivingType,
          receiving_info: filterEmptyFields(receivingInfo),
          card: cardText,
        };
    
        console.log("Order Data:", orderData);
        setOrderData(orderData); // Устанавливаем данные заказа в состояние
      } else {
        console.log('Validation failed');
      }
    };
  

    // Состояние для модального окна
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  
  

  return (
    <>
      <Box sx={{ width: '100%', color: '#000', '& > .MuiBox-root > .MuiBox-root': { p: 1, borderRadius: 2, fontSize: '0.875rem', fontWeight: '700' }}}>
      <Box sx={{ m: 2 }}>
        <Typography variant="h3" gutterBottom>
          Ваш заказ:
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 4, gridTemplateRows: 'auto', gridTemplateAreas: `"cart" "buttons" "customer" "receiving" "order"`, marginLeft: '3%', marginRight: '3%' }}>
          <Box sx={{ gridArea: 'cart', backgroundColor: '#ffeaed', width: '100%' }}>
            <CartItems cartItems={cartItems} onQuantityChange={handleQuantityChange} />
          </Box>

          <Box sx={{ gridArea: 'buttons', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Buttons onClick={() => handleReceivingTypeChange('delivery')} active={activeButton === 'delivery'}>Доставка</Buttons>
            <Buttons onClick={() => handleReceivingTypeChange('pickup')} active={activeButton === 'pickup'}>Самовывоз</Buttons>
          </Box>

          {receivingType && (
            <>
              <Box sx={{ gridArea: 'customer', backgroundColor: '#ffeaed', width: '100%' }}>
                <CustomerInfo setCustomerInfo={setCustomerInfo} />
              </Box>

              <Box sx={{ gridArea: 'receiving', backgroundColor: '#ffeaed', width: '100%' }}>
                {receivingType === 'delivery' ? 
                  <DeliveryForm customerInfo={customerInfo} setReceivingInfo={setReceivingInfo} /> : 
                  <PickupForm setReceivingInfo={setReceivingInfo} />}
              </Box>

              <Box sx={{ gridArea: 'order', backgroundColor: '#ffeaed', width: '100%' }}>
                <TextField label="Текст открытки" multiline rows={2} value={cardText} onChange={(e) => setCardText(e.target.value)} inputProps={{ maxLength: 119 }} sx={{ ...textFieldStyles, minWidth: 300 }} fullWidth />
                <Box sx={{ display: 'flex' }}>
                <Box sx={{ mt: 4 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPrivacyPolicyAccepted}
                          onChange={(e) => {
                            setIsPrivacyPolicyAccepted(e.target.checked);
                            setPrivacyPolicyError(false);
                          }}
                        />
                      }
                      label={
                        <span onClick={handleOpenModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                          Я согласен с политикой конфиденциальности и даю согласие на обработку моих персональных данных.
                        </span>
                      }
                    />
                    {privacyPolicyError && (
                      <Typography variant="body2" color="error">
                        Пожалуйста, примите политику конфиденциальности, чтобы продолжить.
                      </Typography>
                    )}

                    <Box sx={{ mt: 2 }}>
                          <Buttons onClick={handleSubmitOrder}>Оформить заказ</Buttons>
                    </Box>
                  </Box>
                  
                   
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      
      <ModalContent 
      open={modalOpen} 
      handleClose={handleCloseModal} 
      
    >
      <PrivacyPolicyPage />
    </ModalContent>
    </>
  );
};

export default CartPage;
