import React from 'react';
import { Box, Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const DeliveryInfoPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          Информация о доставке
        </Typography>

        <Typography variant="body1" paragraph>
          Курьерская служба "Хочу Цветочкиы"
        </Typography>

        <Typography variant="body1" paragraph>
          Мы предлагаем два способа получения заказов:
        </Typography>

        <Typography variant="body1" paragraph>
          Курьерская доставка — осуществляется ежедневно с 07:00 до 00:00.
        </Typography>
        <Typography variant="body1" paragraph>
          Самовывоз — вы можете забрать свой заказ у нас в магазине по адресу: г. Тольятти, ул. Фрунзе 14В, с 09:00 до 21:00 ежедневно. Для этого укажите "Самовывоз" при оформлении заказа на сайте.
        </Typography>

        <Typography variant="body1" paragraph>
          График и стоимость доставки по районам:
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Район</TableCell>
                <TableCell align="center">09:30-21:00</TableCell>
                <TableCell align="center">07:00-09:30 и 21:00-00:00</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                { name: 'Автозаводской р-н', day: '250 руб.', night: '400 руб.' },
                { name: 'Южное шоссе, Коммунальная, Вокзальная, Цеховая и др.', day: '350 руб.', night: '500 руб.' },
                { name: 'Центральный р-н', day: '350 руб.', night: '500 руб.' },
                { name: 'Комсомольский р-н', day: '400 руб.', night: '550 руб.' },
                { name: 'Шлюзовой р-н / Жигулевское Море', day: '550 руб.', night: '750 руб.' },
                { name: 'Васильевка', day: '600 руб.', night: '850 руб.' },
                { name: 'Верхнее Санчелеево', day: '1050 руб.', night: '1400 руб.' },
                { name: 'Жигулевск', day: '1100 руб.', night: '1500 руб.' },
                { name: 'Зеленовка', day: '800 руб.', night: '1100 руб.' },
                { name: 'Кафе Базилик, 8 миля, Лесная сказка', day: '650 руб.', night: '900 руб.' },
                { name: 'Нижнее Санчелеево', day: '800 руб.', night: '1200 руб.' },
                { name: 'Луначарский', day: '800 руб.', night: '1200 руб.' },
                { name: 'п.о. Копылово', day: '900 руб.', night: '1300 руб.' },
                { name: 'Поволжский', day: '1050 руб.', night: '1400 руб.' },
                { name: 'Подстепки', day: '400 руб.', night: '550 руб.' },
                { name: 'Портпоселок', day: '300 руб.', night: '450 руб.' },
                { name: 'Приморский', day: '300 руб.', night: '450 руб.' },
                { name: 'Русская Борковка', day: '400 руб.', night: '550 руб.' },
                { name: 'Тимофеевка', day: '400 руб.', night: '600 руб.' },
                { name: 'Узюкова', day: '1100 руб.', night: '1500 руб.' },
                { name: 'Федоровка', day: '800 руб.', night: '1200 руб.' },
                { name: 'Хрящевка', day: '1100 руб.', night: '1500 руб.' },
                { name: 'Царское село', day: '300 руб.', night: '450 руб.' },
                { name: 'Ягодное', day: '650 руб.', night: '900 руб.' },
              ].map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell align="center">{row.day}</TableCell>
                  <TableCell align="center">{row.night}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1" paragraph>
          Примечания:
        </Typography>

        <ul>
          <li>Доставка в другие места обговаривается индивидуально.</li>
          <li>Доставка осуществляется ко времени или во временной промежуток, указанный клиентом. Возможны изменения времени доставки в зависимости от загруженности курьерской службы.</li>
          <li>При указании ошибочного адреса или отсутствии получателя по указанному адресу в дату доставки, Компания обязуется приложить все усилия для передачи продукции. В случае неудачи, заказ считается выполненным. Продукция хранится в Салоне цветов в течение суток и может быть самостоятельно получена.</li>
          <li>Если вы не знаете точного адреса получателя, сообщите нам номер телефона и имя. Курьеры сами договорятся о наиболее удобном времени для доставки.</li>
          <li>Допускается техническое опоздание курьера до 15 минут.</li>
          <li>При отсутствии получателя по указанному адресу, цветы могут быть оставлены любому человеку в квартире или сотрудникам офиса.</li>
        </ul>
      </Box>
    </Container>
  );
};

export default DeliveryInfoPage;
