import React from 'react';
import { Box, Typography, Modal, Button } from '@mui/material';

const ModalContent = ({ open, handleClose, title, children }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',  // можно отрегулировать ширину по необходимости
        maxHeight: '80%',  // ограничиваем максимальную высоту модального окна
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        overflow: 'auto',  // добавляем прокрутку для содержимого
      }}>
        <Typography id="modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Box id="modal-description" sx={{ mt: 2 }}>
          {children}
        </Box>
        <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
          Закрыть
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalContent;
