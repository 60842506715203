// src/components/Error404/Error404.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ProductMain from '../MainPage/ProductMain';

const Error404 = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  useEffect(() => {
    axios.get('/api/main-categories/')
      .then(response => {
        const categoryRequests = response.data.map(category =>
          axios.get(`/api/compositions/?categories=${category.slug}`)
            .then(prodResponse => ({ ...category, products: prodResponse.data.results }))
            .catch(error => {
              console.error("Ошибка получения товаров категории:", error);
              return { ...category, products: [] };
            })
        );
        Promise.all(categoryRequests).then(categoriesWithProducts => {
          setCategories(categoriesWithProducts);
        });
      })
      .catch(error => {
        console.error("Ошибка получения категорий:", error);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <Box sx={{ textAlign: 'center', padding: '50px' }}>
      <Typography variant="h1" color="lightpink">404</Typography>
      <Typography variant="h4" color="textSecondary" gutterBottom>
        Страница не найдена
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        Такой страницы не существует, но у нас много красоты!
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoBack}>
        Вернуться на главную
      </Button>
      <Box sx={{ mt: 6 }}>
        {categories.map(category => (
          <Box key={category.slug} sx={{ mt: 6, overflow: 'hidden', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 6 }}>
              <Typography variant="h2">{category.name}</Typography>
              <Button 
                style={{ color: '#f2003c' }} 
                onClick={() => navigate(`/${category.parent}/${category.slug}`)}
              >
                Смотреть всё
              </Button>
            </Box>
            <Slider {...settings}>
              {category.products && category.products.map(product => (
                <Box key={product.id} sx={{ flexGrow: 1 }}>
                  <ProductMain product={product} />
                </Box>
              ))}
            </Slider>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Error404;
