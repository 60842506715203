import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, Link } from '@mui/material';
import SocialMediaActions from './SocialMediaActions';
import axios from 'axios';

const Footer = () => {
  const [categories, setCategories] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/parent-categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <SocialMediaActions />
      <Box
        sx={{
          backgroundColor: '#f5f5f5', // Замените на нужный цветовой код
          paddingY: 0, // Вертикальный паддинг
          paddingX: 0, // Горизонтальный паддинг
          display: 'flex',
          width: '100%',
          justifyContent: 'center', // Изменяем выравнивание на центр
          alignItems: 'center',
          margin: 'auto',
          mb: 0 // Убираем нижний отступ
        }}
      >
        <Box
          sx={{
            width: 'auto', // Устанавливаем ширину контейнера
            flexGrow: 0, // Контейнер не растягивается
            objectFit: 'cover', // Картинка заполняет элемент без искажения
            borderRadius: '10px',
            overflow: 'hidden' // Скрываем излишки, выходящие за границы контейнера
          }}
        >
          <img
            src="/footer_logo.png"
            alt="Logo_footer"
            style={{
              width: '100%', // Картинка масштабируется по ширине контейнера
              height: 'auto', // Высота авто, чтобы сохранять пропорции
              display: 'block', // Сбрасываем возможные внешние стили display
              maxWidth: '100%', // Гарантируем, что картинка не превышает ширину контейнера
              margin: 0, // Сбрасываем возможные внешние отступы
              padding: 0 // Сбрасываем возможные внешние паддинги
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          paddingY: 4,
          paddingX: 2,
          color: '#666', // Устанавливаем цвет текста серым
          marginTop: 0 // Убираем верхний отступ
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box sx={{ flex: '1 1 20%', padding: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Информация</Typography>
            <List>
              <ListItem><Link href="/about" color="inherit">О нас</Link></ListItem>
              <ListItem><Link href="/returns" color="inherit">Возврат и Гарантия</Link></ListItem>
              <ListItem><Link href="/delivery" color="inherit">Условия доставки</Link></ListItem>
              <ListItem><Link href="/privacy-policy" color="inherit">Политика конфиденциальности</Link></ListItem>
            </List>
          </Box>
          <Box sx={{ flex: '1 1 20%', padding: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Магазин</Typography>
            <List>
              {categories.map((category) => (
                <ListItem key={category.id}><Link href={`/${category.slug}`} color="inherit">{category.name}</Link></ListItem>
              ))}
              <ListItem><Link href="/wedding" color="inherit">Свадьба</Link></ListItem>
              <ListItem><Link href="/decoration" color="inherit">Оформление</Link></ListItem>
              <ListItem><Link href="/contact" color="inherit">Контакты</Link></ListItem>
            </List>
          </Box>
          <Box sx={{ flex: '1 1 20%', padding: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Контакты</Typography>
            <List>
              <ListItem><a href="tel:+79171435332" style={{ color: 'inherit', textDecoration: 'none' }}>
                  +7 (917) 143 53 32
                </a></ListItem>
              <ListItem>Доставка с 07:00 до 00:00</ListItem>
              <ListItem>Тольятти, улица Фрунзе 14В</ListItem>
              <ListItem><Link href="mailto:lliviling.work@yandex.ru" color="inherit">lliviling.work@yandex.ru</Link></ListItem>
            </List>
          </Box>
        </Box>
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2">
          {currentYear} © Хочу цветочки | Предложение на сайте не является публичной офертой.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
