import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Создаем контекст
const CartContext = createContext();

// Провайдер для корзины
export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);

  const fetchCartCount = async () => {
    try {
      const response = await axios.get('/api/cart/count/');
      setCartCount(response.data.count);
    } catch (error) {
      console.error('Ошибка при получении количества товаров в корзине:', error);
    }
  };

  useEffect(() => {
    fetchCartCount();
  }, []);

  return (
    <CartContext.Provider value={{ cartCount, setCartCount, fetchCartCount }}>
      {children}
    </CartContext.Provider>
  );
};

// Хук для использования контекста корзины
export const useCart = () => useContext(CartContext);
