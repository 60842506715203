import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';
import { textFieldStyles, typographyStyles } from './formStyles';
import { useFormContext } from './FormContext';

const CustomerInfo = ({ setCustomerInfo }) => {
  const { control, handleSubmit, setError, clearErrors, watch, trigger, formState: { errors } } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      surname: '',
      phone: '+7'
    }
  });

  const { registerValidator } = useFormContext();

  const validatePhone = (phone) => {
    return (phone.match(/\d/g) || []).length === 11 || "Номер телефона должен содержать 11 цифр";
  };

  const onSubmit = (data) => {
    setCustomerInfo(data);
    console.log('КЛиент он сабмит:', data);
  };

  useEffect(() => {
    registerValidator(async () => {
      const result = await trigger();
      return { isValid: result, errors: errors };
    });
  }, [registerValidator, trigger, errors]);

  return (
    <form onBlur={handleSubmit(onSubmit)} style={typographyStyles}>
      <Typography variant="h6" sx={typographyStyles}>
        Заказчик (Вы):
      </Typography>

      <Controller
        name="name"
        control={control}
        rules={{
          required: "Это обязательное поле",
          maxLength: { value: 20, message: "Имя не должно быть длиннее 20 символов" }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Имя"
            variant="outlined"
            size='small'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            sx={textFieldStyles}
          />
        )}
      />

      <Controller
        name="surname"
        control={control}
        rules={{
          maxLength: { value: 20, message: "Фамилия не должна быть длиннее 20 символов" }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Фамилия"
            variant="outlined"
            size='small'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            sx={textFieldStyles}
          />
        )}
      />

      <Controller
        name="phone"
        control={control}
        rules={{
          required: "Это обязательное поле",
          validate: validatePhone
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Телефон"
            variant="outlined"
            size='small'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            sx={textFieldStyles}
          />
        )}
      />
    </form>
  );
};

export default CustomerInfo;
