import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Switch, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';
import { fetchDistricts, fetchDeliveryPrice } from './api';
import { textFieldStyles, typographyStyles } from './formStyles';
import { useFormContext } from './FormContext'; // Импортируем контекст формы

const DeliveryForm = ({ customerInfo, setReceivingInfo }) => {
  const [districts, setDistricts] = useState([]);
  const { control, handleSubmit, watch, setValue, trigger, setError, clearErrors, getValues, formState } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      surname: '',
      phone: '+7',
      find_address: false,
      city: 'Тольятти',
      district: '1',
      street: '',
      house: '',
      flat: '',
      entrance: '',
      floor: '',
      coming_soon: false,
      date: '',
      time: '12:00',
      comment: '',
      delivery_price: ''
    }
  });

  const [useCustomerInfo, setUseCustomerInfo] = useState(false);
  const { registerValidator } = useFormContext(); // Используем контекст формы

  const watchDistrict = watch('district');
  const watchTime = watch('time');
  const findAddress = watch("find_address");
  const comingSoon = watch("coming_soon");

  const requiredMessage = "Это поле обязательно";
  const maxLengthMessage = "Значение не может быть длиннее 20 символов";

  useEffect(() => {
    setValue('city', 'Тольятти');
    setValue('district', '1');
    setValue('time', '12:00');
  }, [setValue]);

  const validateTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours >= 9 && hours < 21 && minutes >= 0 && minutes < 60;
  };

  const handleTimeChange = (field) => (event) => {
    const time = event.target.value;
    field.onChange(event);
    //setReceivingInfo((prev) => ({ ...prev, time }));
    if (time.length === 5) {
      if (!validateTime(time)) {
        setError('time', {
          type: 'manual',
          message: "Пожалуйста, выберите рабочее время между 09:00 и 21:00."
        });
      } else {
        clearErrors('time');
      }
    }
  };

  const validateDate = (date) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(date)) {
      return false;
    }
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return selectedDate >= today;
  };
  
  const handleDateChange = (field) => (event) => {
    const date = event.target.value;
    field.onChange(event);
    //setReceivingInfo((prev) => ({ ...prev, date }));
    if (date.length === 10) {
      if (!validateDate(date)) {
        setError('date', {
          type: 'manual',
          message: "Нельзя доставить в прошлое."
        });
      } else {
        clearErrors('date');
      }
    }
  };

  const onSubmit = (data) => {
    setReceivingInfo(data);
    console.log('Доставка он сабмит:', data);
  };

  useEffect(() => {
    fetchDistricts().then(data => {
      setDistricts(data);
    }).catch(error => {
      console.error('Ошибка при получении районов:', error);
    });

    if (watchDistrict && watchTime) {
      fetchDeliveryPrice(watchDistrict, watchTime)
        .then(price => {
          setValue('delivery_price', price);
        })
        .catch(error => console.error('Ошибка при получении стоимости доставки:', error));
    }
  }, [watchDistrict, watchTime, setValue]);

  // useEffect(() => {
  //   if (useCustomerInfo && customerInfo) {
  //     setValue('name', customerInfo.name);
  //     setValue('surname', customerInfo.surname);
  //     setValue('phone', customerInfo.phone);
  //   } else {
  //     setValue('name', '');
  //     setValue('surname', '');
  //     setValue('phone', '+7');
  //   }
  //   //const data = getValues();
  //   //setReceivingInfo(data); // Установите receivingInfo при изменении useCustomerInfo
  // }, [useCustomerInfo, customerInfo, setValue, getValues, setReceivingInfo]);

  
  // очищаем пустые строки
  const filterEmptyFields = (data) => {
    if (Array.isArray(data)) {
      return data.map(filterEmptyFields);
    } else if (data !== null && typeof data === 'object') {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, value]) => value !== '')
          .map(([key, value]) => [key, filterEmptyFields(value)])
      );
    } else {
      return data;
    }
  };

  

  useEffect(() => {
    registerValidator(async () => {
      const isValid = await trigger();
      let data = getValues();
      console.log('Form data after filtering:', data);
      data = filterEmptyFields(data);
      await setReceivingInfo(data);
      if (isValid) {
        console.log('Form data before filtering:', data);
        await setReceivingInfo(data);
      }
      return { isValid, errors: formState.errors };
    });
  }, [registerValidator, trigger, getValues, formState.errors, setReceivingInfo]);



  return (
    <form onBlur={handleSubmit(onSubmit)} style={typographyStyles}>
      <Typography variant="h6" sx={typographyStyles}>Получатель:</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={useCustomerInfo}
            onChange={(e) => {
              setUseCustomerInfo(e.target.checked);
              if (e.target.checked) {
                setValue('name', customerInfo.name);
                setValue('surname', customerInfo.surname);
                setValue('phone', customerInfo.phone);
              } else {
                setValue('name', '');
                setValue('surname', '');
                setValue('phone', '+7');
              }
              //const data = getValues();
              //setReceivingInfo(data); // Установите receivingInfo при переключении useCustomerInfo
   
            }}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#FF5D9E',
                '& + .MuiSwitch-track': {
                  backgroundColor: '#FF5D9E',
                },
              },
            }}
          />
        }
        label="Получаю сам"
      />
      {!useCustomerInfo && (
        <>
          <Controller
            name="name"
            control={control}
            rules={{
              required: requiredMessage,
              maxLength: { value: 20, message: maxLengthMessage }
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Имя получателя"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                onBlur={() => trigger('name')}
                sx={textFieldStyles}
              />
            )}
          />
          <Controller
            name="surname"
            control={control}
            rules={{ maxLength: { value: 20, message: maxLengthMessage } }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Фамилия"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                sx={textFieldStyles}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: requiredMessage,
              pattern: {
                value: /^\+[0-9]{10,14}$/,
                message: "Номер телефона должен быть в формате +79171435332"
              }
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Телефон получателя"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                onBlur={() => trigger('phone')}
                sx={textFieldStyles}
              />
            )}
          />
        </>
      )}
      <div style={{ marginBottom: '1em' }}></div>
      <Controller
        name="date"
        control={control}
        rules={{ required: requiredMessage }} // Убедитесь, что дата обязательна
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Дата"
            type="date"
            size='small'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            InputLabelProps={{ shrink: true }}
            onChange={handleDateChange(field)}
            sx={textFieldStyles}
          />
        )}
      />
      <div style={{ marginBottom: '1em' }}></div>
      <FormControlLabel
        control={
          <Controller
            name="coming_soon"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#FF5D9E',
                    '& + .MuiSwitch-track': {
                      backgroundColor: '#FF5D9E',
                    },
                  },
                }}
              />
            )}
          />
        }
        label="Уточнить время у получателя"
      />
      {!comingSoon && (
        <>
          <Controller
            name="time"
            control={control}
            rules={{ required: requiredMessage }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Время"
                type="time"
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true }}
                onChange={handleTimeChange(field)}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={textFieldStyles}
              />
            )}
          />
        </>
      )}
      <div style={{ marginBottom: '1em' }}></div>
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Город"
            fullWidth
            InputProps={{ readOnly: true }}
            size='small'
            sx={textFieldStyles}
          />
        )}
      />
      <div style={{ marginBottom: '1em' }}></div>
      <FormControlLabel
        control={
          <Controller
            name="find_address"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#FF5D9E',
                    '& + .MuiSwitch-track': {
                      backgroundColor: '#FF5D9E',
                    },
                  },
                }}
              />
            )}
          />
        }
        label="Уточнить адрес у получателя"
      />
      {!findAddress && (
        <>
          <Controller
            name="district"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth sx={{ ...textFieldStyles, minWidth: 240 }}>
                <InputLabel id="district-label">Район</InputLabel>
                <Select
                  {...field}
                  labelId="district-label"
                  label="Район"
                  size='small'
                >
                  {districts.map((district) => (
                    <MenuItem key={district.id} value={district.id}>
                      {district.district}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="street"
            control={control}
            rules={{
              required: requiredMessage,
              maxLength: { value: 200, message: "Значение не может быть длиннее 200 символов" }
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Улица"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                sx={textFieldStyles}
              />
            )}
          />
          <Controller
            name="house"
            control={control}
            rules={{
              required: requiredMessage,
              maxLength: { value: 20, message: maxLengthMessage }
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Дом"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                sx={textFieldStyles}
              />
            )}
          />
          <Controller
            name="flat"
            control={control}
            rules={{ maxLength: { value: 20, message: maxLengthMessage } }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Квартира"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                sx={textFieldStyles}
              />
            )}
          />
          <Controller
            name="entrance"
            control={control}
            rules={{ maxLength: { value: 20, message: maxLengthMessage } }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Подъезд"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                sx={textFieldStyles}
              />
            )}
          />
          <Controller
            name="floor"
            control={control}
            rules={{ maxLength: { value: 20, message: maxLengthMessage } }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Этаж"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                size='small'
                sx={textFieldStyles}
              />
            )}
          />
        </>
      )}
      <div style={{ marginBottom: '1em' }}></div>
      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Комментарий"
            multiline
            rows={4}
            fullWidth
            size='small'
            sx={{
              ...textFieldStyles,
              minWidth: 300
            }}
          />
        )}
      />
      <div style={{ marginTop: 20 }}>
        <Typography sx={typographyStyles}>
          Стоимость доставки: {watch('delivery_price')}р.
        </Typography>
      </div>
    </form>
  );
};

export default DeliveryForm;
