import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Список изображений для слайдера
const images = [
  '/images/wedding/w1.jpg',
  '/images/wedding/w2.jpg',
  '/images/wedding/w3.jpg',
  '/images/wedding/w4.jpg',
  '/images/wedding/w5.jpg',
  '/images/wedding/w6.jpg',
  '/images/wedding/w7.jpg',
  '/images/wedding/w8.jpg',
  '/images/wedding/w9.jpg',
  '/images/wedding/w10.jpg',
  '/images/wedding/w11.jpg',
  '/images/wedding/w12.jpg',
  // Добавьте сюда пути к вашим изображениям
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const WeddingPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 'none', border: 'none' }}>
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <Box key={index} sx={{ width: '100%' }}>
                  <img 
                    src={image} 
                    alt={`slide-${index}`} 
                    style={{ 
                      width: '100%', 
                      height: 'auto',
                      objectFit: 'cover',
                      borderRadius: '10px' 
                    }} 
                    onError={() => console.error(`Error loading image: ${image}`)}
                    onLoad={() => console.log(`Loaded image: ${image}`)}
                  />
                </Box>
              ))}
            </Slider>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', border: 'none', boxShadow: 'none' }}>
            <CardContent>
              
              <Typography variant="h3" gutterBottom>
                Ваш идеальный свадебный букет и бутоньерка
              </Typography>
              <Typography variant="body1" paragraph>
                Поздравляем с наступающим знаменательным событием! Будь то будущая невеста, жених или заботливый родственник, мы здесь, чтобы сделать ваш свадебный день незабываемым.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Свадебный букет и бутоньерка
              </Typography>
              <Typography variant="body1" paragraph>
                Свадебный букет – это не просто цветы. Это символ вашей любви, акцент вашего образа и деталь, которая останется на всех фотографиях. Ваш букет может быть нежным и романтичным, ярким и смелым, миниатюрным или величественным – всё зависит от вашего желания. Мы поможем вам создать идеальный букет, который подчеркнёт вашу индивидуальность и стиль.
              </Typography>
              <Typography variant="body1" paragraph>
                Не забудьте про бутоньерку для жениха! Она должна гармонировать с вашим букетом и добавлять изысканности его образу. Мы подберём идеальное сочетание цветов и стиля, чтобы каждый элемент выглядел безупречно.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Почему выбирают нас?
              </Typography>
              <ul>
                <li>Индивидуальный подход: Мы не предлагаем шаблоны. Ваш букет будет уникальным, созданным специально для вас.</li>
                <li>Качество и свежесть: Мы используем только самые свежие цветы, чтобы ваши композиции радовали вас на протяжении всего дня.</li>
                <li>Профессионализм: Наши флористы – настоящие мастера своего дела, которые помогут воплотить ваши мечты в реальность.</li>
              </ul>
              <Typography variant="body1" paragraph>
                Доверьте нам создать цветочную магию для вашего особенного дня. Свяжитесь с нами, чтобы обсудить ваши пожелания и начать планирование вашего идеального свадебного букета и бутоньерки.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WeddingPage;
