import React from 'react';
import { Box, Button, Typography, SvgIcon } from '@mui/material';
import Link from '@mui/material/Link';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Импорт иконки WhatsApp
import TelegramIcon from '@mui/icons-material/Telegram'; // Импорт иконки телеграм
import { Icon32LogoVk } from '@vkontakte/icons';


const SocialMediaActions = () => {
  const buttonStyle = {
    borderColor: '#f5f5f5	', // Светло-серая обводка
    backgroundColor: '#f5f5f5	', // Светло-серый фон
    width: '100%', // Занимать всю ширину
    mb: 1, // Нижний отступ между кнопками
    ':hover': {
      borderColor: 'red', // Красная обводка при наведении
      backgroundColor: 'red', // Красный фон при наведении
      color: 'white', // Белый текст при наведении
    },
  };

  return (
    <Box sx={{
      mt: 10,
      textAlign: 'center',
      '& > *': {
        margin: 'auto', // Выравнивание по центру
      },
    }}>
      <Typography variant="h2" sx={{ mb: 4 }}>
        Мы в соцсетях
      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-around', // Равномерное распределение с пространством по бокам
        gap: '8px', // Отступ между кнопками
        mx: '8px', // Отступ с двух сторон
      }}>
        <Button
        component="a"
        href='https://wa.me/+79171435332'
        target="_blank"
        rel="noopener noreferrer"
        variant="outlined"
        startIcon={<WhatsAppIcon style={{ color: '#25D366', fontSize: '32px', }}/>}
        sx={buttonStyle}
        >
        </Button>
        <Button
        component="a"
        href='https://t.me/hochy_cvetochki'
        target="_blank"
        rel="noopener noreferrer"
        variant="outlined"
        startIcon={<TelegramIcon style={{ color: '#0088cc', fontSize: '32px', }} />}
        sx={buttonStyle}
        >
        </Button>
        <Button
        component="a"
        href='https://vk.com/hochycvetochki'
        target="_blank"
        rel="noopener noreferrer"
        variant="outlined"
        sx={buttonStyle}
        >
            < Icon32LogoVk />
        </Button>
      </Box>
    </Box>
  );
};

export default SocialMediaActions;
