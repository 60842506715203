import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme, 'data-clicked': clicked }) => ({
  width: '50%',
  backgroundColor: clicked === 'true' ? '#FF5D9E' : 'lightpink',
  color: 'black',
  borderRadius: '50px',
  padding: '10px 20px',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#FF5D9E',
  },
  [theme.breakpoints.up('md')]: {
    width: '30%',
  },
  margin: '10px',
}));

const CustomButton = ({ onClick, children, active = false }) => {
  return (
    <StyledButton
      variant="contained"
      data-clicked={active.toString()}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
