import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Список изображений для слайдера
const images = [
  '/images/decoration/d1.jpg',
  '/images/decoration/d2.jpg',
  '/images/decoration/d3.jpg',
  '/images/decoration/d4.jpg',
  '/images/decoration/d5.jpg',
  '/images/decoration/d6.jpg',
  '/images/decoration/d7.jpg',
  '/images/decoration/d8.jpg',
  '/images/decoration/d9.jpg',
  '/images/decoration/d10.jpg',
  '/images/decoration/d11.jpg',
  '/images/decoration/d12.jpg',
  '/images/decoration/d13.jpg',
  '/images/decoration/d14.jpg',
  '/images/decoration/d15.jpg',
  // Добавьте сюда пути к вашим изображениям
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const DecorationPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 'none', border: 'none' }}>
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <Box sx={{ width: '100%',    }}>
                  <img 
                    src={image} 
                    alt={`slide-${index}`} 
                    style={{ 
                      width: '100%', 
                      
                      
                       
                      objectFit: 'cover',
                      borderRadius: '10px' 
                    }} 
                    onError={() => console.error(`Error loading image: ${image}`)}
                    onLoad={() => console.log(`Loaded image: ${image}`)}
                  />
                </Box>
              ))}
            </Slider>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', border: 'none', boxShadow: 'none' }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                ОФОРМЛЕНИЕ
              </Typography>
            
              <Typography variant="body1" paragraph>
                Впечатление от мероприятия создается не только программой, но и тем, как оно оформлено. Красивое и с изюминкой оформление поможет вам выделиться и создать настроение всем вашим гостям.
              </Typography>
              <Typography variant="body1" paragraph>
                Мы предлагаем услуги по оформлению мероприятий и фасадов, создавая уникальные и незабываемые локации для ваших событий. Будь то корпоративное событие, детский день рождения, презентация нового продукта или свадьба, мы позаботимся о каждой детали.
              </Typography>
              <Typography variant="body1" paragraph>
                Наши услуги включают:
              </Typography>
              <ul>
                <li>Детские праздники: украшаем воздушными шарами, создаем фотозоны, делаем акценты на имениннике.</li>
                <li>Корпоративные мероприятия: цветочные композиции, корпоративные цвета и логотипы, элегантное оформление ресепшн и столов.</li>
                <li>Свадьбы: эксклюзивные цветочные декорации, фотозоны, детали, которые делают ваш день незабываемым.</li>
                <li>Фасады и входные группы: тематическое оформление, которое привлекает внимание и подчеркивает ваш фирменный стиль.</li>
              </ul>
              <Typography variant="body1" paragraph>
                Мы создаем атмосферу, которая передает ваше настроение и ценности, привлекает внимание и вызывает восторг у гостей. Вся наша работа строится на индивидуальном подходе – никаких шаблонов, только уникальные решения для каждого клиента.
              </Typography>
              <Typography variant="body1" paragraph>
                Мы верим, что каждое мероприятие уникально, и наше оформление помогает сделать его особенным и незабываемым. Доверьтесь нам, и мы создадим для вас атмосферу праздника, которая останется в памяти на долгие годы!
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DecorationPage;
