import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Card, CardMedia, Typography, Button, Grid } from '@mui/material';
import { useCart } from '../Сart/CartContext';
import api from './api';
import ProductSlider from './ProductSlider';

const ProductPage = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const { setCartCount } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/compositions/${slug}/`);
        setProduct(response.data);
      } catch (error) {
        console.error('Ошибка при получении данных о товаре:', error);
        if (error.response && (error.response.status === 404 || error.response.status === 400)) {
          navigate('/not-found'); // Перенаправляем на страницу 404 в случае ошибки 404 или 400
        }
      }
    };
    
    fetchProduct();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0); // Скроллим страницу вверх при изменении slug
  }, [slug]);

  const handleAddToCart = async () => {
    try {
      const count = await api.addToCart(product.id, 1);
      setCartCount(count);  // Обновляем количество товаров в корзине
    } catch (error) {
      console.error('Ошибка при добавлении в корзину:', error);
      
    }
  };

  if (!product) {
    return <Typography>Загрузка...</Typography>;
  }

  return (
    <Box sx={{ mt: 3, px: 2 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Card sx={{ boxShadow: 'none', borderRadius: '10px' }}>
            <CardMedia
              component="img"
              sx={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '10px',
                display: 'block',
                margin: '0 auto'
              }}
              image={product.images}
              alt={product.name}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h3" component="h1" gutterBottom>
            {product.name}
          </Typography>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            {product.price} р.
          </Typography>
          <Box sx={{ p: '8px' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#f2003c', width: 'auto' }}
              fullWidth
              onClick={handleAddToCart}
            >
              В корзину
            </Button>
          </Box>
          <Box sx={{ mt: '30px' }}>
            <Typography variant="body1" paragraph>
              {product.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ProductSlider />
    </Box>
  );
};

export default ProductPage;
