import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';

const drawerWidth = 250;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

export default function Menu({ open, handleDrawerClose }) {
  const theme = useTheme();
  const [categories, setCategories] = useState([]);

  console.log("Menu open:", open); // Здесь добавляем вывод в консоль

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/parent-categories/');
        console.log(response.data); // Отладка ответа от сервера
        setCategories(response.data);
      } catch (error) {
        console.log(error.response);
        console.error('Ошибка при загрузке категорий:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'lightpink', // Фон бокового меню нежно-розовый
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <Typography variant="h6" sx={{ padding: '8px 16px', color: '#f2003c' }}>Цветочная лавка</Typography>
          {categories.map((category) => (
            <ListItem key={category.id} disablePadding>
              <ListItemButton 
                component={Link} 
                to={`/${category.slug}`}
                onClick={handleDrawerClose} 
                sx={{ 
                  color: '#f2003c',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <ListItemText primary={category.name} />
              </ListItemButton>
            </ListItem>
          ))}

          <Divider />

          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/decoration"
              onClick={handleDrawerClose}
              sx={{ 
                color: '#f2003c',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemText primary="Оформление" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/wedding"
              onClick={handleDrawerClose}
              sx={{ 
                color: '#f2003c',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemText primary="Свадьба" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/about"
              onClick={handleDrawerClose}
              sx={{ 
                color: '#f2003c',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemText primary="О нас" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/delivery"
              onClick={handleDrawerClose}
              sx={{ 
                color: '#f2003c',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemText primary="Доставка" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/contact"
              onClick={handleDrawerClose}
              sx={{ 
                color: '#f2003c',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemText primary="Контакты" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" sx={{ color: '#f2003c' }}>
            Тольятти, улица Фрунзе 14В
          </Typography>
          <Typography variant="body2" sx={{ color: '#f2003c' }}>
            Пн-Вс с 09:00 до 21:00
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
}
