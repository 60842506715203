import React from 'react';
import Header from './components/Header/Header'; // Убедитесь, что путь к файлу Header правильный
import Footer from './components/Footer/Footer'; // Убедитесь, что путь к файлу Header правильный
import { CssBaseline, ThemeProvider } from '@mui/material';
import ProductsPage from './components/Composition/ProductsPage';
import theme from './components/theme';
import MainPageCategories from './components/MainPage/MainPageCategories';
import AppRouter from './routes/AppRouter';
import { SessionProvider } from './components/context/SessionContext';
import './fonts.css';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <SessionProvider>
            
      <CssBaseline /> {/* Нормализует стили и предоставляет единообразное основание для стилей MUI */}
      {/* <Header /> */}
      {/* <MainPageCategories /> */}
      {/* <ProductsPage /> */}
      {/* Здесь будут остальные компоненты, например, Router и страницы вашего приложения */}
      {/* <Footer /> */}
      <div className="App">
        <AppRouter /> {/* Таким образом вы включаете роутинг в вашем приложении */}
    </div>
    </SessionProvider>
    </ThemeProvider>
  );
}

export default App;
