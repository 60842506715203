import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { Box, Typography, Button } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ProductMain from '../MainPage/ProductMain';


const ProductSlider = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get('/api/parent-categories/')
      .then(response => {
        const categoryRequests = response.data.map(category =>
          axios.get(`/api/compositions/?categories=${category.slug}`)
            .then(prodResponse => ({ ...category, products: prodResponse.data.results }))
            .catch(error => {
              console.error("Ошибка получения товаров категории:", error);
              return { ...category, products: [] };
            })
        );
        Promise.all(categoryRequests).then(categoriesWithProducts => {
          setCategories(categoriesWithProducts);
        });
      })
      .catch(error => {
        console.error("Ошибка получения категорий:", error);
      });
  }, []);

  // Настройки для Slider
  const settings = {
    dots: false,
    infinite: true, // позволяем слайдеру бесконечно прокручиваться
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1, // изменяем скролл на 1, чтобы листать слайды по одному
    swipeToSlide: true,
    autoplay: true, // Включаем автопрокрутку
    autoplaySpeed: 3000, // Интервал автопрокрутки в миллисекундах
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <Box sx={{ width: '100%' }}>
      {categories.map(category => (
        <Box key={category.slug} sx={{ mt: 6, overflow: 'hidden', width: '100%'}}> {/* Добавляем нижний отступ между категориями */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 6, }}>
            <Typography variant="h2">{category.name}</Typography>
            <Button href="#text-buttons" style={{ color: '#f2003c' }}>Смотреть всё</Button>
          </Box>

          <Slider {...settings}>
            {category.products && category.products.map(product => (
              <Box key={product.id} sx={{ flexGrow: 1 }}> {/* Box может использовать систему стилей MUI */}
              <ProductMain product={product} />
            </Box>
            ))}
          </Slider>
        </Box>
      ))}
    </Box>
  );
};

export default ProductSlider;
