import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Link from '@mui/material/Link';

const HintFlowers = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'lightpink', // Замените на нужный цветовой код
        paddingY: 4, // Вертикальный паддинг
        paddingX: 2, // Горизонтальный паддинг
        textAlign: 'center', // Текст по центру
        position: 'relative', // Для позиционирования кнопки
        mt: 10, //отступ сверху
        height: 'auto',
        boxShadow: 'none', // Убираем тени
        borderRadius: '20px', // Скругляем углы
      }}
    >
      <Typography
        variant="h2"
        component="p"
        sx={{ fontWeight: 'bold', marginBottom: 2 }}
      >
        Намёк на цветочки 🌸
      </Typography>
      <Typography
        //variant="h2"
        component="div" // Используйте "div" вместо "p"
        sx={{ fontWeight: 'bold', marginBottom: 2, fontFamily: "Caveat", fontSize: 20 }}
      >
        Хочу цветочки, намекать не хочу! 😁🌸
        <br /> <div style={{ marginBottom: '1em' }}></div>
        Мы с удовольствием возьмём эту геройскую задачу на себя! 😏 Заполни нашу форму и ожидай самые свежие цветочки от своего принца 🫅🏼
      </Typography>

      <Link href="https://docs.google.com/forms/d/e/1FAIpQLScpNSgUyTukXebngfwvxCOtbAlsVjSfERVFTu7kxpI4KkLp1g/viewform" target="_blank" rel="noopener noreferrer">
        <Button
            variant="contained"
            sx={{
            backgroundColor: '#f2003c', // Используйте нужный цвет кнопки
            ':hover': {
                backgroundColor: 'lightpink', // Цвет при наведении
            },
            mt: 4,
            mb: 3,
            fontFamily: "Caveat",
            fontSize: 20
            }}
        >
            Заполнить форму!
        </Button>
      </Link>
    </Box>
  );
};

export default HintFlowers;
