import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Button, useTheme, useMediaQuery, Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import axios from 'axios';
import Menu from '../Menu/Menu'; // Убедитесь, что путь к файлу Menu верный
import { useCart } from '../Сart/CartContext';
import api from '../Composition/api'; // Импорт функций API

const Header = () => {
//  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate('/cart'); // Перенаправляем пользователя на страницу корзины
  };
  const [searchOpen, setSearchOpen] = useState(false); // Состояние для отображения строки поиска
  const [searchQuery, setSearchQuery] = useState(''); // Состояние для хранения запроса
  const [searchResults, setSearchResults] = useState([]); // Состояние для хранения результатов поиска
  const [categories, setCategories] = useState([]); // Состояние для хранения категорий

  


  console.log("menuOpen in Header:", menuOpen); // Вывод состояния в консоль

  const handleLogoClick = () => {
    navigate('/'); // Перенаправляем пользователя на главную страницу
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const { cartCount, setCartCount } = useCart();

  useEffect(() => {

    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/parent-categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Ошибка при получении категорий:', error);
      }
    };
    fetchCategories();


    const fetchCartCount = async () => {
      try {
        const count = await api.fetchCartCount();
        setCartCount(count);
      } catch (error) {
        console.error('Ошибка при получении количества товаров в корзине:', error);
      }
    };
    fetchCartCount();
  }, [setCartCount]);

  const handleSearchSubmit = async () => {
    try {
      const response = await axios.get(`/api/search/?q=${searchQuery}`);
      navigate(`/search-results?q=${searchQuery}`, { state: { results: response.data } });
      setSearchOpen(false); // Скрываем строку поиска после выполнения поиска
    } catch (error) {
      console.error('Ошибка при поиске:', error);
    }
  };
  
  
  
  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'lightpink', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pt: 1 }}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="menu" 
            sx={{ color: 'black', mr: 2 }} 
            onClick={() => setMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <img src="/logo.png" alt="Logo" style={{ height: '50px' }} onClick={handleLogoClick} />
          <Box sx={{ display: 'flex' }}>
          {searchOpen && (
            <TextField 
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              onKeyPress={(e) => { if (e.key === 'Enter') handleSearchSubmit(); }}
              placeholder="Поиск..."
              size="small"
              sx={{ mr: 2 }}
            />
          )}
          <IconButton sx={{ color: 'black' }} onClick={() => setSearchOpen(!searchOpen)}>
            <SearchIcon />
          </IconButton>
            <IconButton sx={{ color: 'black' }} onClick={handleCartClick}>
              <Badge badgeContent={cartCount} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '100%', justifyContent: 'center', mt: 2 }}>
          {categories.map((category) => (
            <Button 
              key={category.id} 
              color="inherit" 
              sx={{ color: 'black', mx: 2 }}
              onClick={() => navigate(`/${category.slug}`)}
            >
              {category.name}
            </Button>
          ))}
        </Box>
      </Toolbar>
      <Menu open={menuOpen} handleDrawerClose={handleDrawerClose} />
    </AppBar>
  );
};

export default Header;
