import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const CartItems = ({ cartItems, onQuantityChange }) => {
  const calculateTotal = () => {
    return cartItems.reduce((acc, item) => acc + item.total_price, 0);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gap: 2,
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Typography sx={{ gridColumn: '1 / span 1' }}>Наименование</Typography>
      <Typography sx={{ gridColumn: '2 / span 1', textAlign: 'center' }}>Количество</Typography>
      <Typography sx={{ gridColumn: '3 / span 1', textAlign: 'right' }}>Сумма</Typography>

      {cartItems.map((item) => (
        <React.Fragment key={item.composition.id}>  {/* Использование React.Fragment с key */}
          <Box
            sx={{
              gridColumn: '1 / span 1',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <img
              src={item.composition.images}
              alt={item.composition.name}
              style={{ width: '60px', height: 'auto' }}
            />
            <Typography variant="body1">{item.composition.name}</Typography>
          </Box>
          <Box
            sx={{
              gridColumn: '2 / span 1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <IconButton size="small" onClick={() => onQuantityChange(item.composition.id, item.quantity - 1)}>
              <RemoveCircleOutline fontSize="inherit" />
            </IconButton>
            <Typography variant="body1">{item.quantity}</Typography>
            <IconButton size="small" onClick={() => onQuantityChange(item.composition.id, item.quantity + 1)}>
              <AddCircleOutline fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography sx={{ gridColumn: '3 / span 1', textAlign: 'right' }}>{item.total_price}р.</Typography>
        </React.Fragment>
      ))}
      <Typography sx={{ gridColumn: '2 / span 2', textAlign: 'right', fontWeight: 'bold' }}>
        Сумма: {calculateTotal()}р.
      </Typography>
    </Box>
  );
};

export default CartItems;
