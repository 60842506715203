import React from 'react';
import { Box, Typography, Container, Checkbox, FormControlLabel } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          Политика конфиденциальности
        </Typography>

        <Typography variant="body1" paragraph>
          В Хочу Цветочки, мы придаём огромное значение конфиденциальности и защите ваших персональных данных. Мы понимаем, насколько важна для вас приватность, и гарантируем, что все ваши личные данные и информация о заказах будут храниться в строгой секретности.
        </Typography>

        <Typography variant="h4" gutterBottom>
          Наши принципы конфиденциальности:
        </Typography>

        <Typography variant="body1" paragraph>
          1. Защита данных: Мы используем передовые методы шифрования и защиты данных, чтобы обеспечить безопасность ваших персональных данных.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Никаких разглашений: Мы никогда не раскроем, кто сделал заказ, кому и что было заказано, без вашего явного согласия.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Безопасные платежи: Все платежные данные защищены и обрабатываются в соответствии с самыми высокими стандартами безопасности.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Только необходимая информация: Мы собираем и используем только ту информацию, которая необходима для выполнения вашего заказа и улучшения качества наших услуг.
        </Typography>
        <Typography variant="body1" paragraph>
          5. Ваши права: Вы имеете право в любое время запросить информацию о том, какие данные мы храним, и потребовать их удаления.
        </Typography>

        <Typography variant="h4" gutterBottom>
          Обработка и хранение данных
        </Typography>
        <Typography variant="body1" paragraph>
          Ваши персональные данные используются исключительно внутри нашей организации. Мы не передаем ваши данные третьим лицам, за исключением случаев, предусмотренных законом, или если это необходимо для выполнения вашего заказа (например, курьерским службам).
        </Typography>

        <Typography variant="h4" gutterBottom>
          Ваши заказы
        </Typography>
        <Typography variant="body1" paragraph>
          Мы гарантируем полную конфиденциальность информации о ваших заказах. Независимо от того, кто сделал заказ и кому он предназначен, эта информация останется между нами. Даже если кто-то попытается узнать, кто отправил цветы или воздушные шары, мы не раскроем эту информацию без вашего разрешения.
        </Typography>

        <Typography variant="body1" paragraph>
          В Хочу Цветочки, мы уважаем ваше право на конфиденциальность и делаем всё возможное, чтобы ваши личные данные оставались защищенными. Ваше доверие для нас – наивысший приоритет.
        </Typography>

      </Box>
    </Container>
  );
};

export default PrivacyPolicyPage;
