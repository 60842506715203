import React from 'react';
import { Box, Card, CardMedia, CardContent, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../Composition/api';
import { useCart } from '../Сart/CartContext';


const ProducMain = React.forwardRef(({ product }, ref) => {
  const cardContentHeight = '120px';
  const navigate = useNavigate();
  const { setCartCount } = useCart();

  const handleNavigateToProduct = () => {
    navigate(`/product/${product.slug}`); // Изменение для использования slug вместо id
  };

  const handleAddToCart = async () => {
    try {
      const count = await api.addToCart(product.id, 1);
      setCartCount(count);  // Обновляем количество товаров в корзине
    } catch (error) {
      console.error('Ошибка при добавлении в корзину:', error);
      
    }
  };


  return (
    <Grid item xs={6} sm={3} md={3} sx={{ p: '2px' }} ref={ref}> {/* Устанавливаем минимальный отступ */}
      <Card sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Занимаем все доступное вертикальное пространство
        justifyContent: 'space-between', // Распределяем пространство между элементами
        boxShadow: 'none', // Убираем тени
        borderRadius: '10px', // Скругляем углы картинки
      }}>
        <CardMedia onClick={handleNavigateToProduct}
          component="img"
          sx={{
            height: { xs: '300px', sm: '250px', md: '400px' },
            flexGrow: 1, // Картинка будет растягиваться, занимая все доступное пространство
            objectFit: 'cover', // Картинка заполняет элемент без искажения
            borderRadius: '10px',
          }}
          image={product.images}
          alt={product.name}
        />
        <CardContent onClick={handleNavigateToProduct} sx={{ flexGrow: 0, minHeight: cardContentHeight, m: '0px', overflow: 'hidden' }}> {/* Убираем рост, добавляем внутренний отступ */}
          <Typography gutterBottom variant="body2" component="div" color="text.secondary">
            {product.name}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '20px' }}>
            {product.price} р.
          </Typography>
        </CardContent>
        <Box sx={{ p: '8px' }}> {/* Контейнер для кнопки с некоторым паддингом */}
        <Button
              variant="contained"
              sx={{ backgroundColor: '#f2003c', width: 'auto' }}
              fullWidth
              onClick={handleAddToCart}
            >
              В корзину
            </Button>
        </Box>
      </Card>
    </Grid>
  );
});

export default ProducMain;
