import React, { createContext, useContext, useState, useCallback } from 'react';

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  const [validators, setValidators] = useState([]);

  const registerValidator = useCallback((validator) => {
    console.log('Registering validator');
    setValidators((prev) => [...prev, validator]);
  }, []);

  const validateForms = useCallback(async () => {
    console.log('Validating forms with validators:', validators);
    const validationResults = await Promise.all(validators.map((validator) => validator()));
    const isValid = validationResults.every(result => result.isValid);
    const allErrors = validationResults.reduce((acc, result) => ({ ...acc, ...result.errors }), {});
    console.log('Validation results:', validationResults);
    return { isValid, errors: allErrors };
  }, [validators]);

  return (
    <FormContext.Provider value={{ registerValidator, validateForms }}>
      {children}
    </FormContext.Provider>
  );
};
