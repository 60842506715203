import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Outlet,
  ScrollRestoration
} from 'react-router-dom';
import { Box } from '@mui/material';
import MainPageCategories from '../components/MainPage/MainPageCategories';
import ProductsPage from '../components/Composition/ProductsPage';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CartPage from '../components/Сart/CartPage';
import { FormProvider } from '../components/Сart/FormContext';
import ThankYouPage from '../components/Сart/ThankYouPage';
import { CartProvider } from '../components/Сart/CartContext';
import SearchResults from '../components/Composition/SearchResults'; 
import ProductPage from '../components/Composition/ProductPage';
import Error404 from '../components/Error404/Error404';
import ErrorBoundary from '../components/Error404/ErrorBoundary';
import useScrollToTop from '../components/hooks/useScrollToTop'; // Импортируем наш хук
import useYandexMetrika from '../components/hooks/useYandexMetrika';
import DecorationPage from '../components/static/DecorationPage';
import WeddingPage from '../components/static/WeddingPage';
import AboutPage from '../components/static/AboutPage';
import ContactPage from '../components/static/ContactPage';
import ReturnPolicyPage from '../components/static/ReturnPolicyPage';
import PrivacyPolicyPage from '../components/static/PrivacyPolicyPage';
import DeliveryInfoPage from '../components/static/DeliveryInfoPage';

const Layout = () => {
  useScrollToTop(); // Используем хук для прокрутки к верху при изменении маршрута
  useYandexMetrika(); //используем яндекс метрику

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh' }}>
      <Header />
      <Box component="main" sx={{ overflow: 'auto' }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ErrorBoundary><Layout /></ErrorBoundary>}>
      <Route path="/" element={<MainPageCategories />} />
      <Route path="/:categorySlug" element={<ProductsPage />} />
      <Route path="/:categorySlug/:subcategorySlug" element={<ProductsPage />} />
      <Route path="/cart" element={
        <FormProvider>
          <CartPage />
        </FormProvider>
      } />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/search-results" element={<SearchResults />} />
      <Route path="/product/:slug" element={<ProductPage />} />
      <Route path="/decoration" element={<DecorationPage />} />
      <Route path="/wedding" element={<WeddingPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/return" element={<ReturnPolicyPage />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/delivery" element={<DeliveryInfoPage />} />
      <Route path="*" element={<Error404 />} />
    </Route>
  )
);

const AppRouter = () => {
  return (
    <CartProvider>
      <RouterProvider router={router} />
    </CartProvider>
  );
};

export default AppRouter;
