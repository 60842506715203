import axios from 'axios';

export const fetchCartItems = async (setCartItems) => {
  try {
    const response = await axios.get('/api/cart/', { withCredentials: true });
    setCartItems(response.data[0].cart_items);
  } catch (error) {
    console.error('Ошибка при загрузке данных корзины:', error);
  }
};

export const updateCartItemQuantity = async (itemId, newQuantity, fetchCartItems) => {
  try {
    const response = await axios.patch(
      '/api/cart/update-item/',
      { composition_id: itemId, quantity: newQuantity },
      { withCredentials: true }
    );
    if (response.data.status === 'success') {
      fetchCartItems();
    }
  } catch (error) {
    console.error('Ошибка при изменении количества товара:', error);
  }
};

export const removeCartItem = async (itemId, fetchCartItems) => {
  try {
    const response = await axios.delete('/api/cart/delete-item/', {
      data: { composition_id: itemId },
      withCredentials: true,
    });
    if (response.data.status === 'success') {
      fetchCartItems();
    }
  } catch (error) {
    console.error('Ошибка при удалении товара из корзины:', error);
  }
};

export const submitOrder = async (orderData) => {
  try {
    const response = await axios.post('/api/order/', orderData);
    console.log('Заказ успешно оформлен:', response.data);
    return response;
  } catch (error) {
    console.error('Ошибка при оформлении заказа:', error);
  }
};


export const fetchDistricts = async () => {
  try {
    const response = await axios.get('/api/delivery-district/');
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении районов:', error);
    throw error; // Передаем ошибку дальше
  }
};

export const fetchDeliveryPrice = async (districtId, time) => {
  try {
    const response = await axios.post('/api/cart/price-delivery/', {
      district_id: districtId,
      time: time
    });
    return response.data.price;
  } catch (error) {
    console.error('Ошибка при получении стоимости доставки:', error);
    throw error; // Передаем ошибку дальше
  }
};
