import React, { createContext, useContext, useEffect, useReducer } from 'react';

const SessionContext = createContext(null);

function sessionReducer(state, action) {
    switch (action.type) {
        case 'SET_SESSION':
            if (action.sessionid) {
                return { ...state, sessionid: action.sessionid };
            }
            return state;
        case 'CLEAR_SESSION':
            return { ...state, sessionid: null };
        default:
            return state;
    }
}

export function SessionProvider({ children }) {
    // Попытка загрузить состояние сессии из локального хранилища
    const [state, dispatch] = useReducer(sessionReducer, { sessionid: localStorage.getItem('sessionid') });

    // Эффект для сохранения состояния сессии в локальном хранилище
    useEffect(() => {
        localStorage.setItem('sessionid', state.sessionid);
    }, [state.sessionid]);

    return (
        <SessionContext.Provider value={{ state, dispatch }}>
            {children}
        </SessionContext.Provider>
    );
}

export function useSession() {
    return useContext(SessionContext);
}
