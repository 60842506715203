const CACHE_PREFIX = 'productData';
const CACHE_TIME_PREFIX = 'productDataTime';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 1 день в миллисекундах

const generateCacheKey = (category, subcategory) => {
  return `${CACHE_PREFIX}-${category}-${subcategory || ''}`;
};

const generateCacheTimeKey = (category, subcategory) => {
  return `${CACHE_TIME_PREFIX}-${category}-${subcategory || ''}`;
};

export const saveToCache = (category, subcategory, data) => {
  const cacheKey = generateCacheKey(category, subcategory);
  const cacheTimeKey = generateCacheTimeKey(category, subcategory);
  localStorage.setItem(cacheKey, JSON.stringify(data));
  localStorage.setItem(cacheTimeKey, Date.now().toString());
};

export const getFromCache = (category, subcategory) => {
  const cacheKey = generateCacheKey(category, subcategory);
  const cacheTimeKey = generateCacheTimeKey(category, subcategory);
  const data = localStorage.getItem(cacheKey);
  const timestamp = localStorage.getItem(cacheTimeKey);

  if (data && timestamp) {
    const age = Date.now() - parseInt(timestamp, 10);
    if (age < CACHE_DURATION) {
      return JSON.parse(data);
    }
  }

  return null;
};
