import React from 'react';
import { useNavigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Логируйте ошибку в какой-либо сервис
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Вы можете рендерить любую пользовательскую запасную UI
      return (
        <div>
          <h1>Что-то пошло не так.</h1>
          <button onClick={() => this.props.navigate('/')}>Вернуться на главную</button>
        </div>
      );
    }

    return this.props.children; 
  }
}

// Оборачиваем в хук useNavigate
const ErrorBoundaryWithNavigate = (props) => {
  const navigate = useNavigate();
  return <ErrorBoundary {...props} navigate={navigate} />;
};

export default ErrorBoundaryWithNavigate;
