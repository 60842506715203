import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Список изображений для слайдера
const images = [
  '/images/about/a0.jpg',
  '/images/about/a1.jpg',
  '/images/about/a2.jpg',
  '/images/about/a3.jpg',
  '/images/about/a4.jpg',
  '/images/about/a5.jpg',
  '/images/about/a6.jpg',
  '/images/about/a7.jpg',
  '/images/about/a8.jpg',
  '/images/about/a9.jpg',
  //'/images/about/a10.jpg',
  '/images/about/a11.jpg',
  '/images/about/a12.jpg',
  // Добавьте сюда пути к вашим изображениям
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const AboutPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 'none', border: 'none' }}>
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <Box key={index} sx={{ width: '100%' }}>
                  <img 
                    src={image} 
                    alt={`slide-${index}`} 
                    style={{ 
                      width: '100%', 
                      height: 'auto',
                      objectFit: 'cover',
                      borderRadius: '10px' 
                    }} 
                    onError={() => console.error(`Error loading image: ${image}`)}
                    onLoad={() => console.log(`Loaded image: ${image}`)}
                  />
                </Box>
              ))}
            </Slider>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', border: 'none', boxShadow: 'none' }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                О НАС
              </Typography>
              <Typography variant="body1" paragraph>
                Привет! Меня зовут Ксения, и я директор цветочного салона Хочу Цветочки. Я хочу рассказать, почему люди выбирают именно нас.
              </Typography>
              <Typography variant="body1" paragraph>
                Мы открыли наш первый магазин в Тольятти в 2017 году и сразу решили, что будем предлагать только самые красивые и свежие цветы. Мы не следуем шаблонам и не предлагаем "колхозные" букеты. Наше имя - это наша гордость, и мы делаем букеты, как для себя.
              </Typography>
              <Typography variant="body1" paragraph>
                Наши флористы - настоящие профессионалы, и я с гордостью доверяю им делать букеты для своих друзей и родственников. Мы используем только лучшие цветы, которые доставляем напрямую из Эквадора, Кении, Колумбии и Нидерландов. Благодаря этому, наши цены ниже, а качество выше, чем в среднем по городу.
              </Typography>
              <Typography variant="body1" paragraph>
                Мы объездили международные выставки, побывали на плантациях и договорились о прямых поставках. Это позволяет нам привозить интересные сорта роз, кустовых роз и других цветов, чтобы всегда радовать наших клиентов.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Наши принципы просты:
              </Typography>
              <ul>
                <li>Делаем букеты, как для своих близких.</li>
                <li>Работают только профессиональные флористы.</li>
                <li>Доставляем качественно, чтобы букеты оставались свежими.</li>
                <li>Используем только лучшие цветы.</li>
              </ul>
              <Typography variant="body1" paragraph>
                Приходите к нам за цветами, и вы увидите, как мы вкладываем душу в каждый букет.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutPage;
