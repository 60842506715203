import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CategoryFilters = ({ onFilterChange, categorySlug }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (categorySlug) {
          const response = await axios.get(`/api/subcategories/${categorySlug}/`);
          setCategories(response.data);
        }
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
        throw error; 
      }
    };

    fetchCategories();
  }, [categorySlug]);

  const handleCategoryClick = (category) => {
    if (selectedCategory === category.slug) {
      setSelectedCategory(null);
      navigate(`/${categorySlug}`);
      onFilterChange(categorySlug, null); // Передача null для подкатегории
    } else {
      setSelectedCategory(category.slug);
      navigate(`/${categorySlug}/${category.slug}`);
      onFilterChange(categorySlug, category.slug);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, my: 2 }}>
      {categories.map((category) => (
        <Button
          key={category.id}
          variant="outlined"
          sx={{
            borderColor: selectedCategory === category.slug ? 'pink' : '#f2003c',
            color: selectedCategory === category.slug ? '#f2003c' : 'red',
            backgroundColor: selectedCategory === category.slug ? 'pink' : 'transparent',
            borderRadius: '20px',
            '&:hover': {
              backgroundColor: 'pink',
              color: '#f2003c',
              borderColor: '#f2003c',
            },
          }}
          onClick={() => handleCategoryClick(category)}
        >
          {category.name}
        </Button>
      ))}
    </Box>
  );
};

export default CategoryFilters;
