// src/hooks/useYandexMetrika.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useYandexMetrika() {
  const location = useLocation();

  useEffect(() => {
    if (window.ym) {
      window.ym(97962628, 'hit', location.pathname + location.search);
    }
  }, [location]);
}

export default useYandexMetrika;
