import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import ProductCard from './ProductCard'; // Путь к файлу ProductCard может отличаться

const ProductsGrid = ({ products, setLoaderRef }) => {
  if (!Array.isArray(products)) {
    return <Typography>Товары не найдены или произошла ошибка загрузки.</Typography>;
  }

  return (
    <Grid container spacing={2}>
      {products.map((product, index) => {
        // Если элемент является последним в массиве, передаем ref
        const isLastElement = index === products.length - 1;
        return (
          <ProductCard
            key={product.id}
            product={product}
            ref={isLastElement ? setLoaderRef : null}
          />
        );
      })}
    </Grid>
  );
};

export default ProductsGrid;
