import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, Grid, Typography } from '@mui/material';
import ProductCard from '../Composition/ProductCard';

const SearchResults = () => {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const query = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (query) {
        try {
          const response = await axios.get(`/api/search/?q=${query}`);
          setSearchResults(response.data);
        } catch (error) {
          console.error('Ошибка при получении результатов поиска:', error);
          throw error; 
        }
      }
    };

    fetchSearchResults();
  }, [query]);

  return (
    <Box sx={{ p: 2 }}>
    <Grid container spacing={2}>
      {query ? (
        searchResults.length > 0 ? (
          searchResults.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))
        ) : (
          <Typography>Ничего не найдено</Typography>
        )
      ) : (
        <Typography>Введите запрос для поиска</Typography>
      )}
    </Grid>
  </Box>

  );
};

export default SearchResults;