import React, { useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ProductsGrid from './ProductsGrid';
import CategoryFilters from './CategoryFilters';
import useInfiniteScroll from './useInfiniteScroll';
import axios from 'axios';
import { saveToCache, getFromCache } from '../utils/cache'; // Импортируем функции для кэширования

const ProductsPage = () => {
  const { categorySlug, subcategorySlug } = useParams();
  const cachedData = getFromCache(categorySlug, subcategorySlug);
  const [products, setProducts] = useState(cachedData || []);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);
  const navigate = useNavigate();
  const [redirectedTo404, setRedirectedTo404] = useState(false);

  const fetchProducts = async (pageNum, category, subcategory) => {
    setLoading(true);
    const url = subcategory
      ? `/api/compositions/?page=${pageNum}&categories=${category}&categories=${subcategory}`
      : `/api/compositions/?page=${pageNum}&categories=${category}`;
    try {
      const response = await axios.get(url);
      if (pageNum === 1) {
        setProducts(response.data.results);
        saveToCache(category, subcategory, response.data.results); // Сохраняем данные в кэш
      } else {
        setProducts(prev => [...prev, ...response.data.results]);
        saveToCache(category, subcategory, [...products, ...response.data.results]); // Обновляем кэш
      }
      setHasMore(!!response.data.next);
      setPage(pageNum + 1);
    } catch (error) {
      if (!redirectedTo404 && error.response && (error.response.status === 400 || error.response.status === 404)) {
        setRedirectedTo404(true);
        navigate('*'); // Перенаправляем на страницу 404 в случае ошибки 400
      } else {
        console.error('Ошибка при загрузке товаров:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!cachedData) {
      fetchProducts(1, categorySlug, subcategorySlug);
    }
  }, [categorySlug, subcategorySlug]);

  useInfiniteScroll(loader, () => {
    if (hasMore) {
      fetchProducts(page, categorySlug, subcategorySlug);
    }
  }, hasMore, loading);

  const handleFilterChange = (newSelectedCategorySlug, newSelectedSubcategorySlug) => {
    setPage(1);
    setProducts([]);
    fetchProducts(1, newSelectedCategorySlug, newSelectedSubcategorySlug);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CategoryFilters onFilterChange={handleFilterChange} categorySlug={categorySlug} />
      <ProductsGrid products={products} />
      <div ref={loader} style={{ height: '20px', visibility: 'hidden' }}></div>
    </Box>
  );
};

export default ProductsPage;
