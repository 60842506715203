import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Container, Grid, Card, CardContent, Button } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Список изображений для слайдера
const images = [
  '/images/contact/c1.jpg',
  '/images/contact/c2.jpg',
  '/images/contact/c3.jpg',
  // Добавьте сюда пути к вашим изображениям
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const ContactPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 'none', border: 'none' }}>
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <Box key={index} sx={{ width: '100%' }}>
                  <img 
                    src={image} 
                    alt={`slide-${index}`} 
                    style={{ 
                      width: '100%', 
                      height: 'auto',
                      objectFit: 'cover',
                      borderRadius: '10px' 
                    }} 
                    onError={() => console.error(`Error loading image: ${image}`)}
                    onLoad={() => console.log(`Loaded image: ${image}`)}
                  />
                </Box>
              ))}
            </Slider>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', border: 'none', boxShadow: 'none' }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                КОНТАКТЫ
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ color: '#f2003c' }}>
                Режим работы
              </Typography>
              <Typography variant="body1" paragraph>
                Пн-Вс с 09:00 до 21:00
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ color: '#f2003c' }}>
                Адрес
              </Typography>
              <Typography variant="body1" paragraph>
                Тольятти, улица Фрунзе 14В
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ color: '#f2003c' }}>
                Телефон
              </Typography>
              <Typography variant="body1" paragraph>
                <a href="tel:+79171435332" style={{ color: 'inherit', textDecoration: 'none' }}>
                  +7 (917) 143 53 32
                </a>
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ color: '#f2003c' }}>
                E-mail
              </Typography>
              <Typography variant="body1" paragraph>
                lliviling.work@yandex.ru
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactPage;
