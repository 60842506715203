import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardMedia, CardContent, Typography, Button, Grid, Container } from '@mui/material';
import axios from 'axios';
import { useSession } from '../context/SessionContext';
import api from './api'; // Импорт функций API
import { useCart } from '../Сart/CartContext';

const ProductCard = React.forwardRef(({ product }, ref) => {
  const cardContentHeight = '120px';
  const navigate = useNavigate();
  const { setCartCount } = useCart();

  

  const handleAddToCart = async () => {
    try {
      const count = await api.addToCart(product.id, 1);
      setCartCount(count);  // Обновляем количество товаров в корзине
    } catch (error) {
      console.error('Ошибка при добавлении в корзину:', error);
      
    }
  };

  const handleNavigateToProduct = () => {
    navigate(`/product/${product.slug}`); // Изменение для использования slug вместо id
  };


  return (
    <Grid item xs={6} sm={3} md={3} sx={{ p: '2px' }} ref={ref}> {/* Устанавливаем минимальный отступ */}
      <Card sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Занимаем все доступное вертикальное пространство
        justifyContent: 'space-between', // Распределяем пространство между элементами
        boxShadow: 'none', // Убираем тени
        borderRadius: '10px', // Скругляем углы картинки
      }}>
        <CardMedia
          component="img"
          sx={{
            //height: 200,
            flexGrow: 1, // Картинка будет растягиваться, занимая все доступное пространство
            objectFit: 'cover', // Картинка заполняет элемент без искажения
            borderRadius: '10px',
          }}
          image={product.images}
          alt={product.name}
          onClick={handleNavigateToProduct} // Добавляем обработчик для перехода на страницу товара
        />
        <CardContent onClick={handleNavigateToProduct} sx={{ flexGrow: 0, minHeight: cardContentHeight, m: '0px', overflow: 'hidden' }}> {/* Убираем рост, добавляем внутренний отступ */}
          <Typography gutterBottom variant="body2" component="div" color="text.secondary">
            {product.name}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '20px' }}>
            {product.price} р.
          </Typography>
        </CardContent>
        <Box sx={{ p: '8px' }}> {/* Контейнер для кнопки с некоторым паддингом */}
          <Button
            variant="contained"
            sx={{ backgroundColor: '#f2003c', width: 'auto' }} // Цвет кнопки и ширина
            fullWidth
            onClick={handleAddToCart} // Добавляем обработчик для добавления товара в корзину
          >
            В корзину
          </Button>
        </Box>
      </Card>
    </Grid>
  );
});

export default ProductCard;
