// src/styles/formStyles.js
export const textFieldStyles = {
    m: 1, 
    width: '22ch', 
    backgroundColor: 'white', 
    borderRadius: '15px', 
    '.MuiOutlinedInput-notchedOutline': { 
        border: 'none' 
    }, 
    '.MuiOutlinedInput-root': { 
        borderRadius: '15px' 
    }
};

export const typographyStyles = {
    m: 1
};
